import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { StrapiCertificationCTA } from '../../components/StrapiComponents/StrapiCertificationCTA';
import Divider from '../../components/Divider';
import Card from '../../components/Card';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { CertificationsIndexQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type PageContextType = GlobalPageContext;

const CertificationsIndex: React.FC<PageProps<CertificationsIndexQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const { certifications, certificationCta } = data.strapi;
  const pages = get(data, 'strapi.pages', []);

  const intl = useIntl();

  if (!websiteLocale) return null;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.certifications.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.certifications.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />

      <div className={classnames('container', styles.root)}>
        <div className="columns">
          <div className="column is-5">
            <h1 className="title color-a mt-6 mb-3">
              <FormattedMessage id="certificationIndexHeading" defaultMessage="English Certifications" />
            </h1>
            <p className="is-text-1 black-2">
              <FormattedMessage
                id="certificationIndexDescription"
                defaultMessage="All over the world some of the best universities, colleges and companies demand proof of your English level. Find out the information you need to get your English certification now."
              />
            </p>
          </div>
        </div>

        <Divider paddingTop="70px" marginBottom="75px" />

        {!certifications?.length ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noCertifications)}>
            <h4 className="title is-4">
              <FormattedMessage id="noCertificationsIndexHeading" defaultMessage="No English Certifications found" />
            </h4>
            <p>
              <FormattedMessage
                id="noCertificationsIndexDescription"
                defaultMessage="We have no English Certifications at this current time."
              />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {compact(certifications).map(({ id, name, slug, excerpt }) => {
            if (!slug) return null;
            return (
              <Card
                urlText={intl.formatMessage({ id: 'certificationsCardText', defaultMessage: 'View Certification' })}
                slugBase={pageContext.pathPrefixes.certifications}
                key={`promotion-${id}`}
                short={excerpt ?? ''}
                title={name ?? 'N/A'}
                slug={slug}
              />
            );
          })}
        </div>
      </div>
      {certificationCta && <StrapiCertificationCTA {...certificationCta} />}
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(CertificationsIndex));

export const query = graphql`
query CertificationsIndex($websiteLocaleId: ID!, $certificationCtaId: ID!, $hasDefaultCertificationCta: Boolean!, $limit: Int!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    certificationCta(id: $certificationCtaId) @include(if: $hasDefaultCertificationCta) {
      data {
        id
        attributes {
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          ...CertificationCta
        }
      }
    }
    certifications(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}}
      sort: "name:asc"
      locale: $locale
      pagination: {limit: $limit}
    ) {
      data {
        id
        attributes {
          excerpt
          name
          slug
          seo {
            ...SEOComponent
          }
        }
      }
    }
    pages(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}, index_page: {eq: "Certifications"}}
      locale: $locale
    ) {
      data {
        id
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
